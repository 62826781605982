var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "금산마을 이야기",
      "text": "금산군에 있는 260개 마을의 발전을 위한 기초자료를 구축하고자 마을자원과 현황을 조사하고, 주민의 사업수요를 들어보았습니다.",
      "image": "/images/story/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('map-image', _vm._b({}, 'map-image', {
    parent: _vm.parent
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "tit mb-18 mb-md-24 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.STORY_REGION_TYPES[_vm.parent].text))]), _c('v-divider', {
    staticClass: "v-divider--dark mb-32 mb-md-40"
  }), _c('v-row', {
    staticClass: "row--sm"
  }, _vm._l(_vm.regionItems, function (region, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6"
      }
    }, [_c('map-dialog', _vm._b({
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            staticClass: "map-button",
            attrs: {
              "block": "",
              "outlined": "",
              "x-large": ""
            }
          }, 'v-btn', attrs, false), on), [_vm._v(_vm._s(region.name))])];
        }
      }], null, true)
    }, 'map-dialog', {
      region
    }, false))], 1);
  }), 1)], 1)], 1), _c('v-card', {
    staticClass: "e-book-banner mt-60 mt-md-60",
    attrs: {
      "flat": "",
      "img": "/images/map/e-book.jpg",
      "rounded": "xl",
      "href": "https://drive.google.com/drive/folders/17fpfQVqE1CAqQzZF8yCeRGYA9z-BUoqF?usp=sharing",
      "target": "_blank"
    }
  }, [_c('v-row', {
    staticClass: "h-100",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "font-size-24 font-size-md-28 primary--text text--darken-4 font-weight-bold"
  }, [_vm._v("260 마을 스토리 E-Book 전체보기")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-double-right.svg"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }